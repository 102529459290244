<template>
    <div>
        <iq-card>
            <b-row>
                <b-col class="mb-3">
                    <b-button variant="primary" @click="pdfExport" class="mr-2 water-irrigation-pdf-button">
                        {{  $t('globalTrans.print') }}
                    </b-button>
                </b-col>
            </b-row>
            <template>
                <b-row>
                    <item-view :prop-data="itemsToView"/>
                </b-row>
            </template>
        </iq-card>
    </div>
</template>
<script>
import ItemView from '@/components/structure/itemView'
import { helpers } from '@/mixins/helper-functions'
import { dateFormat, numberFormatBn } from '@/Utils/fliter'
import customObject from '@/store/custom.js'
import { irriSchemeServiceBaseUrl } from '@/config/api_config'
import Pdf from './pdf'

export default {
    name: 'Show',
    props: ['id', 'commonObj', 'list'],
    components: { ItemView },
    created () {
        if (this.id) {
            const tmp = this.getAppInfo()
            Object.freeze(tmp)
            this.smartCardApplication = this.formatList(tmp)
        }
    },
    data () {
        return {
            smartCardApplication: {},
            showData: {
                official: {},
                personal: {},
                address: {},
                land: {},
                application: {}
            }
        }
    },
    computed: {
        isBn () {
            return this.$i18n.locale === 'bn'
        },
        itemsToView () {
            const officialFormatted = [
                {
                    title1: this.$t('globalTrans.application_id'),
                    value1: this.showData.official.application_id,
                    title2: this.$t('globalTrans.id_serial'),
                    value2: this.$n(this.showData.official.id_serial, { useGrouping: false }),
                    lg: '4,8'
                },
                {
                    title1: this.$t('org_pro.organization'),
                    value1: this.isBn ? this.showData.official.org_name_bn : this.showData.official.org_name,
                    title2: this.$t('cardPayment.training_info'),
                    value2: this.showData.official.training_info,
                    lg: '4,8'
                }
            ]
            const personalFormatted = [
                {
                    title1: this.$t('globalTrans.profileImage'),
                    value1: `<div class="add-img-user profile-img-edit">
                                <img style="max-width: 230px;max-height: 150px" fluid src="${irriSchemeServiceBaseUrl + 'download-attachment?file=uploads/smart-card-apps/original/' + this.showData.personal.attachment}" alt="profile-pic" />
                            </div>`,
                    config: {
                        value1: {
                            type: 'html'
                        }
                    },
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                },
                {
                    title1: this.$t('globalTrans.name'),
                    value1: this.isBn ? this.showData.personal.name_bn : this.showData.personal.name,
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                },
                {
                    title1: this.$t('globalUserData.email'),
                    value1: this.showData.personal.email,
                    title2: this.$t('globalUserData.mobile_no'),
                    value2: numberFormatBn(this.showData.personal.mobile_no, this, { useGrouping: false })
                },
                {
                    title1: this.$t('cardPayment.date_of_birth'),
                    value1: dateFormat(this.showData.personal.date_of_birth),
                    title2: this.$t('cardPayment.marital_status'),
                    value2: this.isBn ? this.showData.personal.marital_status_bn
                        : this.showData.personal.marital_status_en
                },
                {
                    title1: this.$t('cardPayment.father_name'),
                    value1: this.isBn ? this.showData.personal.father_name_bn : this.showData.personal.father_name,
                    title2: this.$t('cardPayment.mother_name'),
                    value2: this.isBn ? this.showData.personal.mother_name_bn : this.showData.personal.mother_name
                },
                {
                    title1: this.$t('globalUserData.gender'),
                    value1: this.isBn ? this.showData.personal.gender_bn
                        : this.showData.personal.gender_en,
                    title2: this.$t('globalUserData.spouse_name'),
                    value2: this.isBn ? this.showData.personal.spouse_name_bn : this.showData.personal.spouse_name
                },
                {
                    title1: this.$t('cardPayment.no_of_child'),
                    value1: this.$n(this.showData.personal.no_of_child),
                    title2: this.$t('globalUserData.earnings'),
                    value2: this.$n(this.showData.personal.earnings)
                },
                {
                    title1: this.$t('globalUserData.qualification'),
                    value1: this.showData.personal.qualification,
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                }
            ]
            const addressFormatted = [
                {
                    title1: this.$t('farmerOperator.village'),
                    value1: this.isBn ? this.showData.address.far_village_bn : this.showData.address.far_village,
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                },
                {
                    title1: this.$t('complain.district'),
                    value1: this.isBn ? this.showData.address.district_name_bn
                        : this.showData.address.district_name,
                    title2: this.$t('complain.division'),
                    value2: this.isBn ? this.showData.address.division_name_bn
                        : this.showData.address.division_name
                },
                {
                    title1: this.$t('complain.upazila'),
                    value1: this.isBn ? this.showData.address.upazilla_name_bn
                        : this.showData.address.upazilla_name,
                    title2: this.$t('complain.union'),
                    value2: this.isBn ? this.showData.address.union_name_bn
                        : this.showData.address.union_name
                },
                {
                    title1: this.$t('cardPayment.ward_no'),
                    value1: this.$n(this.showData.address.ward_no),
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                }
            ]
            const landFormatted = [
                {
                    title1: this.$t('cardPayment.barga_land'),
                    value1: this.showData.land.barga_land,
                    title2: this.$t('cardPayment.crop_plan'),
                    value2: this.showData.land.crop_plan
                },
                {
                    title1: this.$t('cardPayment.lease_land'),
                    value1: this.showData.land.lease_land,
                    title2: this.$t('cardPayment.owned_land'),
                    value2: this.showData.land.owned_land
                },
                {
                    title1: this.$t('cardPayment.total_land'),
                    value1: this.showData.land.total_land,
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                }
            ]
            const applicationFormatted = [
                {
                    title1: this.$t('externalUserIrrigation.reissue_status'),
                    value1: this.isBn ? this.showData.application.reissue_status_bn
                        : this.showData.application.reissue_status_en,
                    title2: this.$t('globalTrans.status'),
                    value2: this.isBn ? this.showData.application.status_bn
                        : this.showData.application.status_en,
                    lg: '5,7'
                }
            ]
            const reportFormatted = [
                {
                    title1: this.$t('cardPayment.review_note'),
                    value1: this.isBn ? (this.showData.other.review_note_bn ? this.showData.other.review_note_bn : 'N/A')
                        : (this.showData.other.review_note ? this.showData.other.review_note : 'N/A'),
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                },
                {
                    title1: this.$t('complain.reject_note'),
                    value1: this.isBn ? (this.showData.other.reject_note_bn || 'N/A')
                        : (this.showData.other.reject_note || 'N/A'),
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                }
            ]
            return {
                title: '',
                data:
                    [
                        {
                            title: this.$t('globalTrans.application_info'),
                            type: 'list',
                            data: applicationFormatted
                        },
                        {
                            title: this.$t('globalTrans.official_information'),
                            type: 'list',
                            data: officialFormatted
                        },
                        {
                            title: this.$t('globalTrans.personal_information'),
                            type: 'table',
                            data: personalFormatted
                        },
                        {
                            title: this.$t('globalTrans.address'),
                            type: 'table',
                            data: addressFormatted
                        },
                        {
                            title: this.$t('globalTrans.land_information'),
                            type: 'table',
                            data: landFormatted
                        },
                        {
                            title: this.$t('globalTrans.other_information'),
                            type: 'table',
                            data: reportFormatted
                        }
                    ]
            }
        }
    },
    methods: {
        viewItems () {
            return this.itemsToView
        },
        getAppInfo () {
            const tmp = typeof this.list !== 'undefined' ? this.list : this.$store.state.CardPayment.smartCardApplicationLists
            const list = Object.freeze(tmp)
            return list.find(item => item.id === this.id)
        },
        formatList (data) {
            const commonObj = typeof this.commonObj !== 'undefined' ? this.commonObj : this.$store.state.commonObj
            const orgList = commonObj.organizationProfileList
            const divisionList = commonObj.divisionList
            const districtList = commonObj.districtList
            const upazilaList = commonObj.upazilaList
            const unionList = commonObj.unionList
            const genderList = customObject.genderList
            const reissueStatusList = customObject.reissueStatus
            const smartCardStatusList = customObject.smartCardStatus
            const maritalStatusList = customObject.maritalStatus

            const orgPro = orgList.find(org => org.value === data.org_id)
            const division = divisionList.find(item => item.value === data.far_division_id)
            const district = districtList.find(item => item.value === data.far_district_id)
            const upazilla = upazilaList.find(item => item.value === data.far_upazilla_id)
            const union = unionList.find(item => item.value === data.far_union_id)
            const gender = genderList.find(item => item.value === data.gender)
            const reissueStatus = reissueStatusList.find(item => item.id === data.reissue_status)
            const smartCardStatus = smartCardStatusList.find(item => item.id === data.status)
            const maritalStatus = maritalStatusList.find(item => item.value === data.marital_status)

            const mergedObj = Object.assign({}, data,
                { org_name: orgPro.text_en, org_name_bn: orgPro.text_bn },
                { division_name: division.text_en, division_name_bn: division.text_bn },
                { district_name: district.text_en, district_name_bn: district.text_bn },
                { upazilla_name: upazilla.text_en, upazilla_name_bn: upazilla.text_bn },
                { union_name: union.text_en, union_name_bn: union.text_bn },
                { gender_en: gender.text_en, gender_bn: gender.text_bn },
                { reissue_status_en: reissueStatus ? reissueStatus.name : 'N/A', reissue_status_bn: reissueStatus ? reissueStatus.name_bn : 'N/A' },
                { status_en: smartCardStatus ? smartCardStatus.name : 'N/A', status_bn: smartCardStatus ? smartCardStatus.name_bn : 'N/A' },
                { marital_status_en: maritalStatus.text_en, marital_status_bn: maritalStatus.text_bn }
            )
            const personalItems = [
                'name', 'name_bn',
                'email', 'mobile_no', 'date_of_birth',
                'marital_status_en', 'marital_status_bn',
                'father_name', 'father_name_bn',
                'mother_name', 'mother_name_bn',
                'gender_en', 'gender_bn',
                'spouse_name', 'spouse_name_bn',
                'no_of_child', 'earnings', 'nid', 'qualification', 'attachment'
            ]

            const addressItems = [
                'district_name', 'district_name_bn',
                'division_name', 'division_name_bn',
                'upazilla_name', 'upazilla_name_bn',
                'union_name', 'union_name_bn', 'ward_no',
                'far_village', 'far_village_bn'
            ]

            const landItems = [
                'barga_land',
                'crop_plan',
                'crop_plan_bn',
                'lease_land',
                'owned_land',
                'total_land'
            ]

            const officialItems = [
                'org_name',
                'org_name_bn',
                'training_info',
                'id_serial',
                'application_id'
            ]

            const applicationItems = [
                'reissue_status_en',
                'reissue_status_bn',
                'status_en',
                'status_bn'
            ]

            const otherItems = [
                'review_note',
                'review_note_bn',
                'reject_note',
                'reject_note_bn'
            ]

            const application = helpers.filterObjectByArrayItem(mergedObj, applicationItems)
            const official = helpers.filterObjectByArrayItem(mergedObj, officialItems)
            const personal = helpers.filterObjectByArrayItem(mergedObj, personalItems)
            const address = helpers.filterObjectByArrayItem(mergedObj, addressItems)
            const land = helpers.filterObjectByArrayItem(mergedObj, landItems)
            const other = helpers.filterObjectByArrayItem(mergedObj, otherItems)
            this.showData = { application, official, personal, address, land, other }
            return mergedObj
        },
        pdfExport () {
            const reportTitle = this.$t('globalTrans.application_info')
            Pdf.exportPdfDetails(reportTitle, this, this.showData)
        }
    }
}
</script>
