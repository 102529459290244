<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('cardPayment.application_report') }}</h4>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro.organization')"
                    label-for="org_id"
                    >
                    <b-form-select
                    plain
                    v-model="search.org_id"
                    :options="organizationList"
                    id="org_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('cardPayment.application_id')"
                        label-for="application_id"
                        >
                        <b-form-input
                            id="application_id"
                            v-model="search.application_id"
                            placeholder=""
                            ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('cardPayment.application_type')"
                        label-for="reissue_status"
                        >
                        <b-form-select
                        plain
                        v-model="search.reissue_status"
                        :options="smartCardReissueStatusList"
                        id="reissue_status"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="defaultReissueVal">{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('globalTrans.status')"
                        label-for="status"
                        >
                        <b-form-select
                        plain
                        v-model="search.status"
                        :options="smartCardStatusList"
                        id="status"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="defaultStatusVal">{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('globalTrans.from_date')"
                    label-for="from_date"
                    >
                        <flat-pickr class="form-control"
                            v-model="search.from_date"
                            :placeholder="$t('globalTrans.select_date')"
                        ></flat-pickr>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('globalTrans.to_date')"
                    label-for="to_date"
                    >
                        <flat-pickr class="form-control"
                            v-model="search.to_date"
                            :placeholder="$t('globalTrans.select_date')"
                        ></flat-pickr>
                    </b-form-group>
                </b-col>
                <b-col cols="12" class="text-right">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>

        </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('cardPayment.application_report') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button class="btn_add_new mr-2" @click="pdfExport">
                    <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                </b-button>
                <export-excel
                    class="btn btn_add_new"
                    :data="dataCustomize"
                    :fields= "json_fields"
                    :title="$t('cardPayment.application_report')"
                    :worksheet="$t('cardPayment.application_report')"
                    name="application-report.xls">
                    <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
                <b-row v-show="showHeading">
                    <b-col>
                        <div class="container">
                            <div>
                                <b-img width='50px' :src="irriSchemeServiceBaseUrl + organization.left_logo" fluid alt="Left Logo"></b-img>
                            </div>
                            <div class="main-title">
                                <div>
                                    <h5 class="org-name">{{ ($i18n.locale==='bn') ? organization.text_bn :  organization.text }}</h5>
                                </div>
                                <div>
                                    <h6 class="org-address">{{ ($i18n.locale==='bn') ? organization.address_bn :  organization.address }}</h6>
                                </div>
                                <div>
                                    <h5 class="report-name">{{ $t('cardPayment.application_report') }}</h5>
                                </div>
                            </div>
                            <div>
                                <p class="pull-left"><b-img width='50px' :src="irriSchemeServiceBaseUrl + organization.right_logo" fluid alt="Right Logo"></b-img></p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
              <b-row mt-5>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="applicationReportList" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(view)="data">
                      <b-button class="mr-1" title="application_view" v-b-modal.modal-details variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                    </template>
                    <template v-slot:cell(generated_card)="data">
                      <b-button class="btn btn-success" v-b-modal.modal-card @click="cardView(data.item)"><i class="fa fa-id-card" aria-hidden="true"></i> {{ $t('cardPayment.card_view') }}</b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-details" size="lg" :title="$t('cardPayment.application_view')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <ApplicationDetails :id="testId" :list="smartCardList" :commonObj="commonObj"/>
    </b-modal>
    <b-modal id="modal-card" size="lg" :title="$t('cardPayment.card')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <CardModal :id="editItemId" :item="item"/>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import ApplicationDetails from '../ApplicationDetails'
import CardModal from './CardModal'
import excel from 'vue-excel-export'
import Vue from 'vue'
import ExportPdf from '@/Utils/export-pdf'
import { mapGetters } from 'vuex'

Vue.use(excel)

const excelColumn = {
  'Application ID': 'appId',
  'Applicant Name': 'appName',
  'Application Type': 'appType',
  status: 'status_en'
}
const excelColumnBn = {
  'আবেদন আইডি': 'appId',
  'আবেদনকারীর নাম': 'appName',
  'আবেদনের  প্রকার': 'appType',
  অবস্থা: 'status_name_bn'
}
export default {
    name: 'UiDataTable',
    components: {
        ApplicationDetails,
        CardModal
    },
    data () {
        return {
            defaultReissueVal: '',
            defaultStatusVal: '',
            editItemId: '',
            pagination: {
                currentPage: 1,
                totalRows: 0,
                slOffset: 1
            },
            showData: false,
            showHeading: false,
            search: {
                org_id: '0',
                application_id: '',
                reissue_status: '',
                status: '',
                from_date: '',
                to_date: ''
            },
            testId: 0,
            organization: {},
            applicationReportList: [],
            rows: [],
            item: '',
            irriSchemeServiceBaseUrl: irriSchemeServiceBaseUrl
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        commonObj () {
          return this.$store.state.commonObj
        },
        smartCardList () {
            return this.$store.state.CardPayment.smartCardApplicationLists
        },
        json_fields: function () {
            return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
        },
        dataCustomize () {
            const tmp = this.applicationReportList
            const applicationReportList = Object.freeze(tmp)
            applicationReportList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    item.appId = item.application_id
                    item.appName = item.name_bn
                    item.appType = item.application_type_name_bn
                    item.status_bn = item.status_name_bn
                } else {
                    item.appId = item.application_id
                    item.appName = item.name
                    item.appType = item.application_type_name
                    item.status_en = item.status_name
                }
                return Object.assign({}, item)
            })
                return applicationReportList
            },
            organizationList: function () {
                return this.$store.state.commonObj.organizationProfileList
            },
            smartCardStatusList: function () {
            const ObjectData = this.$store.state.commonObj.smartCardStatusList
            return ObjectData.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.id, text: obj.name_bn }
                } else {
                    return { value: obj.id, text: obj.name }
                }
                })
            },
            smartCardReissueStatusList: function () {
            const ObjectData = this.$store.state.commonObj.reissueStatus
            return ObjectData.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.id, text: obj.name_bn }
                } else {
                    return { value: obj.id, text: obj.name }
                }
                })
            },
            loading () {
            return this.$store.state.commonObj.loading
            },
            listReload () {
            return this.$store.state.commonObj.listReload
            },
            loadingState () {
            if (this.listReload) {
                return true
            } else if (this.loading) {
                return true
            } else {
                return false
            }
            },
            currentLocale () {
            return this.$i18n.locale
            },
            columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('cardPayment.application_id'), class: 'text-left' },
                { label: this.$t('cardPayment.applicant_name'), class: 'text-left' },
                { label: this.$t('cardPayment.application_type'), class: 'text-left' },
                { label: this.$t('globalTrans.status'), class: 'text-left' },
                { label: this.$t('cardPayment.application_view'), class: 'text-left' },
                { label: this.$t('cardPayment.attachment_document_link'), class: 'text-left' },
                { label: this.$t('cardPayment.generated_card'), class: 'text-left' }
                ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'application_id' },
                { key: 'name_bn' },
                { key: 'application_type_name_bn' },
                { key: 'status_name_bn' },
                { key: 'view' },
                { key: 'link' },
                { key: 'generated_card' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'application_id' },
                { key: 'name' },
                { key: 'application_type_name' },
                { key: 'status_name' },
                { key: 'view' },
                { key: 'link' },
                { key: 'generated_card' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    watch: {
        loadingState: function (newVal, oldVal) {
            if (newVal) {
                this.loadData()
            }
        }
    },
    mounted () {
        core.index()
        if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
            this.search = Object.assign({}, this.search, {
                org_id: this.authUser.org_id
            })
        } else if (this.authUser.role_id === 0) {
            this.search = Object.assign({}, this.search, {
                org_id: this.authUser.office_detail.org_id
            })
        }
    },
    methods: {
        default () {
            return {
                id: this.rows.length,
                name: '',
                name_bn: ''
            }
        },
        searchData () {
            if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
                this.search = Object.assign({}, this.search, {
                    org_id: this.authUser.org_id
                })
            } else if (this.authUser.role_id === 0) {
                this.search = Object.assign({}, this.search, {
                    org_id: this.authUser.office_detail.org_id
                })
            }
            if (this.search.org_id !== '0' || this.search.application_id !== '' || this.search.reissue_status !== '' || this.search.status !== '' || this.search.from_date !== '' || this.search.to_date !== '') {
                this.loadData()
                this.organizationData()
                if (this.search.org_id !== '0') {
                    this.showHeading = true
                }
            }
        },
        cardView (item) {
            this.editItemId = item.id
            this.item = item
        },
        details (item) {
            this.testId = item.id
        },
        organizationData () {
            const objectData = this.$store.state.commonObj.organizationProfileList
            const orglist = objectData.find(item => item.value === this.search.org_id)

            RestApi.getData(irriSchemeServiceBaseUrl, '/report-heading/list', { org_id: this.search.org_id }).then(response => {
                if (response.success) {
                    const orgObject = response.data.data[0]
                    const orgData = {
                        left_logo: orgObject.left_logo,
                        right_logo: orgObject.right_logo,
                        address: orgObject.address,
                        address_bn: orgObject.address_bn
                    }
                this.organization = Object.assign({}, orglist, orgData)
                }
            })
        },
        loadData () {
            RestApi.getData(irriSchemeServiceBaseUrl, 'farmer-smart-card-app/report-list', this.search).then(response => {
                if (response.success) {
                    this.dataList(response)
                }
            })
        },
        dataList (data) {
            if (data.data.length !== 0) {
                this.showData = true
            }
            const organizationList = this.$store.state.commonObj.organizationProfileList
            const statusList = this.$store.state.commonObj.smartCardStatusList
            const applicationTypeList = this.$store.state.commonObj.reissueStatus
            const pumpOperatorData = data.data.map(item => {
                const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
                const statusObject = statusList.find(farStatus => farStatus.id === item.status)
                const applicationTypeObject = applicationTypeList.find(reissueStatus => reissueStatus.id === item.reissue_status)
                const orgData = {
                    org_name: orgObject.text,
                    org_name_bn: orgObject.text_bn
                }
                const statusData = {
                    status_name: statusObject.name,
                    status_name_bn: statusObject.name_bn
                }
                const applicationTypeData = {
                    application_type_name: applicationTypeObject.name,
                    application_type_name_bn: applicationTypeObject.name_bn
                }
            return Object.assign({}, item, orgData, statusData, applicationTypeData)
        })
        this.applicationReportList = pumpOperatorData
        this.$store.dispatch('CardPayment/getSMCApplicationList', this.applicationReportList)
        },
        pdfExport () {
            const rowData = this.getPdfData()
            const labels = [
                { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
                { text: this.$t('cardPayment.application_id'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
                { text: this.$t('cardPayment.applicant_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
                { text: this.$t('cardPayment.application_type'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
                { text: this.$t('globalTrans.status'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
            ]
            rowData.unshift(labels)
            const reportTitle = this.$i18n.locale === 'en' ? 'Application Report' : 'আবেদন প্রতিবেদন'
            const columnWids = ['15%', '*', '*', '*', '*']
            ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids)
        },
        getPdfData () {
            const keys = [
                { key: 'serial_no' },
                { key: 'application_id' },
                { key: this.$i18n.locale === 'en' ? 'name' : 'name_bn' },
                { key: this.$i18n.locale === 'en' ? 'application_type_name' : 'application_type_name_bn' },
                { key: this.$i18n.locale === 'en' ? 'status_name' : 'status_name_bn' }
            ]
            var serial = 0
            const listData = this.applicationReportList.map(item => {
                serial += 1
                const rowData = keys.map((keyItem, index) => {
                    if (keyItem.key === 'serial_no') {
                        return { text: this.$n(serial) }
                    }
                return { text: item[keyItem.key] }
                })
                return rowData
            })
            return listData
        }
    }
}
</script>
<style scoped>
 .container {
     display: flex;
     margin-bottom: 15px;
     justify-content: center;
 }
 .report-name {
     text-align: center;
 }
 .org-name {
     text-align: center;
 }
 .org-address {
     text-align: center;
 }
 .main-title {
     padding: 10px;
 }
</style>
